const appConfig = {
    speakers: [
        "hi_male",
        "hi_male_2",
        "hi_male_3",
        "hi_female",
        "hi_female_2",
        "bn_male",
        "bn_female",
        "kn_male",
        "kn_male_2",
        "kn_female",
        "ml_male",
        "ml_female",
        "ta_male",
        "ta_female",
        "te_male",
        "te_female",
        "te_female_2",
        "gu_male",
        "gu_female",
        "or_male",
        "or_female",
        "as_male",
        "as_female",
        "mr_male",
        "mr_male_2",
        "mr_female",
        "mr_female_2",
        "pa_male",
        "pa_female",
        "en_female",
        "en_male_2",
        "te_male_2",
        "kn_female_2",
        "bn_female_2"
    ],
    languages: [
        "hindi",
        "bengali",
        "kannada",
        "malayalam",
        "tamil",
        "telugu",
        "gujarati",
        "odia",
        "assamese",
        "marathi",
        "punjabi",
        "english"
    ],
    languageMap: {
        "as": "assamese",
        "bn": "bengali",
        "en": "english",
        "gu": "gujarati",
        "hi": "hindi",
        "kn": "kannada",
        "ml": "malayalam",
        "mr": "marathi",
        "or": "odia",
        "pa": "punjabi",
        "ta": "tamil",
        "te": "telugu",
        // "fr": "french",
        // "ru": "russian",
        // "es": "spanish",
        // "de": "german",
    },
    defaultSpeaker: {
        'english': 'en_female',
        "hindi": "hi_female",
        "bengali": "bn_female",
        "kannada": "kn_female",
        "malayalam": "ml_female",
        "tamil": "ta_female",
        "telugu": "te_female",
        "gujarati": "gu_female",
        "odia": "or_female",
        "assamese": "as_female",
        "marathi": "mr_female",
        "punjabi": "pa_female",
    },
    // local URL
    // backendUrl: "http://localhost:5000/api/"
    // Dev URL
    // backendUrl: "http://20.193.184.3:3000/api/"
    // Prod URL
    backendUrl: "https://texttospeech.reverieinc.com/api/"
}

export default appConfig;