import React, { useState, useEffect } from 'react';
import useAudioPlayer from '../hooks/useAudioPlayer';
import Bar from './Bar';
import moment from 'moment';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import audio from '../assets/amitabh_corona.mp3';
import appConfig from '../appConfig';

const AudioPlayer = ({ audioId, textChanged, convertAudio, autoPlay, setAutoPlay }) => {
    // const [duration, setDuration] = useState('00:00');
    const { curTime, duration, playing, setPlaying, setClickedTime, volume, setVolume } = useAudioPlayer();
    // const [autoPlay, setAutoPlay] = useState({ play: false, audioId });

    const calculateTime = (secs) => {
        const minutes = Math.floor(secs / 60);
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutes}:${returnedSeconds}`;
    }
    
    // useEffect(() => {
    //     const $audio = document.getElementById('audio');
    //     if($audio) {
    //         console.log($audio.duration);
    //         $audio.addEventListener('loadeddata', () => {
    //             setDuration($audio.duration);
    //         })
    //     }
    // }, [])

    const handlePlay = () => {
        if(playing) {
            setPlaying(false);
        } else {
            if(textChanged) {
                convertAudio(true);
                // setAutoPlay({ play: true, prevId: audioId });
            }
            setPlaying(true);
        }
    }

    function calcClickedVolume(e) {
        const clickPositionInPage = e.pageX;
        const bar = document.querySelector(".volume-level");
        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;
        const clickPositionInBar = clickPositionInPage - barStart;
        const timePerPixel = 100 / barWidth;
        return timePerPixel * clickPositionInBar;
    }

    const handleVolumeChange = (e) => {
        const newVolume = calcClickedVolume(e);
        // console.log(volume);
        setVolume(newVolume);
    }

    useEffect(() => {
        if(autoPlay) {
            setPlaying(true);
            setAutoPlay(false);
            // setAutoPlay(prev => {return {...prev, play: false}})
        }
    }, [autoPlay])


    return (
        <div className='audio-player-container'>
            <p>Preview</p>
            <audio id="audio" src={`${appConfig.backendUrl}get_audio/${audioId}`} style={{ display: 'none' }} />
            <span className='play-icon' onClick={handlePlay} >{playing ? <PauseIcon style={{ fill: '#741b47' }} /> : <PlayArrowIcon style={{ fill: '#741b47' }} />}</span>
            {/* <button className='play-icon'></button> */}
            <div id="seeker-container">
                <span id="current-time">{calculateTime(curTime)}</span>
                {/* <input type="range" style={{ accentColor: '#741b47' }} id="seek-slider" max="100" defaultValue="0" /> */}
                <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)} />
                <span id="duration">{calculateTime(duration)}</span>
            </div>
            <div id="volume-container">
                <VolumeDownIcon id="mute-icon" />
                {/* <input type="range" id="volume-slider" style={{ accentColor: '#741b47' }} max="100" defaultValue="100" /> */}
                <div className="volume-level" onClick={handleVolumeChange} style={{ background: `linear-gradient(to right, #741b47 ${volume}%, #dee5ef 0)` }}></div>
            </div>
        </div>
    )
}

export default AudioPlayer;