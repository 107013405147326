import React from 'react';
import { Card, Button } from 'antd';
import AudioPlayer from './AudioPlayer';
import CachedIcon from '@mui/icons-material/Cached';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';

const DownloadCard = ({ status, audioId, convertAudio, handleDownloadModal, handleTryAgain, textChanged, autoPlay, setAutoPlay }) => {


    return (
        <Card style={{ border: 'solid .5px #dadce0', boxShadow: '6px 8px 30px 0 rgba(0, 0, 0, 0.06)', borderRadius: '10px', marginTop: '.2em', height: '8em' }} bodyStyle={{ height: '100%', padding: '1em' }}>
            {status === "editing" && <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Button onClick={convertAudio} className='convert-btn'>Covert To Speech</Button>
            </div>}
            {status === "converting" && <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Button className="converting-btn"><CachedIcon style={{ marginRight: '.5em' }} /> Converting..</Button>
            </div>}
            {status === "convert_error" && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <InfoIcon style={{ fill: '#ce1e15' }} />
                <span style={{ color: '#ce1e15', marginLeft: '1em' }}>Failed to convert the entered text, please try again</span>
                <span onClick={handleTryAgain} style={{ textDecoration: 'underline', color: '#777c86', marginLeft: '1em', cursor: 'pointer' }}>Try Again</span>
            </div>}
            {status === "converted" && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                <AudioPlayer audioId={audioId} textChanged={textChanged} convertAudio={convertAudio} autoPlay={autoPlay} setAutoPlay={setAutoPlay} />
                <Button onClick={convertAudio} className="download-btn">Convert Again</Button>
                <Button onClick={handleDownloadModal} className="download-btn"><DownloadIcon style={{ marginRight: '.5em' }} /> Download</Button>
            </div>}
        </Card>
    )
}

export default DownloadCard;