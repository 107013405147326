// import logo from './logo.svg';
// import './App.css';
import { useState } from 'react';
import { Row, Col, Button } from 'antd';
import Root from './components/Root';
import HowToUse from './components/HowToUse';
import WhoCanUse from './components/WhoCanUse';
import Feedback from './components/Feedback';
// import Contact from './components/Contact';
import revLogo from './assets/reverie-logo.svg';

function App() {
    const [modal, setModal] = useState(false);

    return (
        <div>
            <Row justify='center' style={{ paddingTop: '2em' }}>
              <Col span={18}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <span style={{ borderRadius: '4px', backgroundColor: '#741b47', color: '#fff', padding: '.5em' }}>TTS</span>
                    <span style={{ color: '#741b47', fontSize: '16px', marginLeft: '.5em' }}>Converter</span>
                    <span style={{ color: '#93979f', marginLeft: '1em', fontSize: '.9em' }}>Powered by Reverie Language Technologies</span>
                  </div>
                  <div>
                    <img src={revLogo} style={{ height: '3em' }} />
                  </div>
                </div>
                <Root />
              </Col>
            </Row>
            <HowToUse />
            <WhoCanUse />
            {/* <Contact /> */}
            <div style={{ textAlign: 'center', color: '#93979f', display: 'grid', alignItems: 'center', height: '15vh' }}>
                Powered by Reverie Language Technologies
            </div>
            <div style={{ position: 'fixed', bottom: '2em', right: '2em' }}>
                <Button type="primary" onClick={e => setModal(true)}>Feedback</Button>
            </div>
            <Feedback modal={modal} setModal={setModal} />
        </div>
    );
}

export default App;
