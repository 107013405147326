import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import toastr from 'toastr';
import "toastr/build/toastr.css";


toastr.options = {
  positionClass: "toast-top-right"
}
toastr.options.showMethod = "slideDown";
toastr.options.hideMethod = "slideUp";
toastr.options.progressBar = true;
toastr.options.closeButton = true;
toastr.options.preventDuplicates = true;
toastr.options.closeDuration = 900;
// toastr.options.positionClass = "toast-top-center"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
