import React, { useState } from 'react';
import { Modal, Input, Typography, Button, Divider } from 'antd';
import axios from 'axios';
import appConfig from '../appConfig';


const { Text, Title } = Typography;
const { TextArea } = Input;

const Feedback = ({ modal, setModal }) => {
    const [email, setEmail] = useState('');
    const [often, setOften] = useState('');
    const [problem, setProblem] = useState('');
    const [improve, setImprove] = useState('');


    const submitFeedback = () => {
        const body = { email, often, problem, improve };
        axios.post(`${appConfig.backendUrl}submit_feedback`, { body }).then(res => {
            setEmail('');
            setOften('');
            setProblem('');
            setImprove('');
            setModal(false);
        })
    }

    return (
        <Modal
            visible={modal}
            width={500}
            onCancel={e => setModal(false)}
            centered
            closable
            footer={null}
            bodyStyle={{ padding: '0' }}
        >
            <div style={{ textAlign: 'center', padding: '2em', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Title level={3} style={{ margin: '0' }}>Help us improve</Title>
            </div>
            <Divider style={{ borderBottom: '1px solid rgba(0,0,0,0.1)', margin: '0' }} />
            <div style={{ padding: '2em' }}>
                <div style={{ margin: '0 0 1em' }}>
                    <Title level={4} style={{ marginBottom: '.3em' }}>What is your email address?</Title>
                    <Input value={email} onChange={e => setEmail(e.target.value)} style={{ width: '100%', borderRadius: '4px', minHeight: '3em' }} placeholder="Enter your email address" />
                </div>
                <div style={{ margin: '1em 0' }}>
                    <Title level={4} style={{ marginBottom: '.3em' }}>How often do you use our app?</Title>
                    <Input value={often} onChange={e => setOften(e.target.value)} style={{ width: '100%', borderRadius: '4px', height: '3em' }} placeholder="Everyday/once a week/bi-weekly" />
                </div>
                <div style={{ margin: '1em 0' }}>
                    <Title level={4} style={{ marginBottom: '.3em' }}>What is the motivation to use our app?</Title>
                    <Input value={problem} onChange={e => setProblem(e.target.value)} style={{ width: '100%', borderRadius: '4px', minHeight: '3em' }} placeholder="What problem does it solve for you?" />
                </div>
                <div style={{ margin: '1em 0' }}>
                    <Title level={4} style={{ marginBottom: '.3em' }}>What would you like to see improved the most?</Title>
                    <TextArea value={improve} onChange={e => setImprove(e.target.value)} style={{ width: '100%', borderRadius: '4px', minHeight: '6em', resize: 'none' }}  />
                </div>
                <div>
                    <Button type="primary" style={{ height: '3em', width: '100%' }} onClick={submitFeedback}>Submit Feedback</Button>
                </div>
            </div>
        </Modal>
    )
}

export default Feedback;