import React from 'react';
import { Row, Col } from 'antd';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const WhoCanUse = () => {



    return (
        <Row justify='center' align='middle' style={{ minHeight: '60vh', backgroundColor: '#fff', padding: '2em' }}>
            <Col span={18}>
                <div style={{ color: '#00070c', fontSize: '20px', fontWeight: '600', width: '50%' }}>
                    Who can use Reverie's <br/>Text-to-Speech Converter?
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '2fr 3fr', gridGap: '1em', marginTop: '2em' }}>
                    <div style={{ backgroundColor: '#ffebf5', borderRadius: '8px', height: 'auto', padding: '3em' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ backgroundColor: '#f2cbde', height: '3em', width: '3em', borderRadius: '50%', display: 'grid', placeItems: 'center' }}>
                                <PersonOutlineIcon style={{ fill: '#741b47' }} />
                            </div>
                            <div style={{ color: '#741b47', marginLeft: '.7em', fontSize: '24px', fontWeight: '600' }}>
                                Personal Use
                            </div>
                        </div>
                        <div style={{ color: '#00070c', marginTop: '2em' }}>
                            Use our text-to-speech converter and get
                        </div>
                        <div style={{ marginTop: '1em' }}>
                            <div className="uses-item">
                                <CheckCircleOutlineIcon style={{ fill: '#741b47' }} />
                                <span>Instagram Videos</span>
                            </div>
                            <div className="uses-item">
                                <CheckCircleOutlineIcon style={{ fill: '#741b47' }} />
                                <span>Slideshow Videos</span>
                            </div>
                            <div className="uses-item">
                                <CheckCircleOutlineIcon style={{ fill: '#741b47' }} />
                                <span>Devotional Videos</span>
                            </div>
                            <div className="uses-item">
                                <CheckCircleOutlineIcon style={{ fill: '#741b47' }} />
                                <span>Explainer Videos</span>
                            </div>
                            <div className="uses-item">
                                <CheckCircleOutlineIcon style={{ fill: '#741b47' }} />
                                <span>Edu Accounts</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#ebfaf9', borderRadius: '8px', height: 'auto', padding: '3em' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ backgroundColor: '#c1f7f3', height: '3em', width: '3em', borderRadius: '50%', display: 'grid', placeItems: 'center' }}>
                                <CurrencyRupeeIcon style={{ fill: '#00c9bd' }} />
                            </div>
                            <div style={{ color: '#00c9bd', marginLeft: '.7em', fontSize: '24px', fontWeight: '600' }}>
                                Commercial Use
                            </div>
                        </div>
                        <div style={{ color: '#00070c', marginTop: '2em' }}>
                            Use our text-to-speech converter in
                        </div>
                        <div style={{ marginTop: '1em', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                            <div className="uses-item">
                                <CheckCircleOutlineIcon style={{ fill: '#00c9bd' }} />
                                <span>Youtube Video Narration</span>
                            </div>
                            <div className="uses-item">
                                <CheckCircleOutlineIcon style={{ fill: '#00c9bd' }} />
                                <span>Real Estate Videos</span>
                            </div>
                            <div className="uses-item">
                                <CheckCircleOutlineIcon style={{ fill: '#00c9bd' }} />
                                <span>Audiobooks & Podcasts</span>
                            </div>
                            <div className="uses-item">
                                <CheckCircleOutlineIcon style={{ fill: '#00c9bd' }} />
                                <span>eLearning Materials</span>
                            </div>
                            <div className="uses-item">
                                <CheckCircleOutlineIcon style={{ fill: '#00c9bd' }} />
                                <span>Sales Videos</span>
                            </div>
                            <div className="uses-item">
                                <CheckCircleOutlineIcon style={{ fill: '#00c9bd' }} />
                                <span>Call Center & IVR systems</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default WhoCanUse;