import React from 'react';
import { Row, Col } from 'antd';


const HowToUse = () => {



    return (
        <Row justify='center' align='middle' style={{ minHeight: '80vh', backgroundColor: '#f4f6fc', padding: '2em' }}>
            <Col span={18}>
                <span style={{ color: '#00070c', fontSize: '20px', fontWeight: '600' }}>How to use TTS converter?</span>
                <p style={{ marginTop: '2em' }}>
                TTS Converter is a free online text-to-speech platform from Reverie Langauge Technologies. The platform is supported by Standard TTS engines and Neural TTS (NTTS), along with Machine Learning (ML) and Artificial Intelligence (AI). Technologies to take customer interaction to the next level by converting text into human-like voices. We currently support 11 Indian languages. 
                </p>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1em' }}>
                    <div className="step-card">
                        <div className='step-card-header'>Step 1</div>
                        <p className="step-card-text">
                            Type the text you'd like to convert into speech. You can type the font in any of the 11 supported Indian languages.
                        </p>
                    </div>
                    <div className="step-card">
                        <div className='step-card-header'>Step 2</div>
                        <p className="step-card-text">
                            Next, select the AI engine of choice: Standard TTS or Neural TTS. Choosing NTTS will give you the most natural human-like text-to-speech voices AI supports. 
                        </p>
                    </div>
                    <div className="step-card">
                        <div className='step-card-header'>Step 3</div>
                        <p className="step-card-text">
                            Select 'Language & Regions'  and Choose the Voice 'Male/Female'
                        </p>
                    </div>
                    <div className="step-card">
                        <div className='step-card-header'>Step 4</div>
                        <p className="step-card-text">
                            Click on 'Convert to Speech' and Voila; you have now converted your Text-to-Speech! :) 
                        </p>
                    </div>
                </div>
                <div style={{ marginTop: '2em', width: '60%' }}>
                    <p style={{ color: '#93979f' }}>
                        (You can also try out our advanced settings option - where you can edit voice effects, pitch tuning, voice speed, and audio settings - audio format and sample rate.)
                    </p>
                </div>
            </Col>
        </Row>
    )
}

export default HowToUse;