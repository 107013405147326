import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Modal, Typography, Input, Button } from 'antd';
import TextareaConf from './TextareaConf';
import DownloadCard from './DownloadCard';
import axios from 'axios';
import appConfig from '../appConfig';
import toastr from 'toastr';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

const { Text, Paragraph } = Typography;

const emailVal = value => (
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Invalid email'
        : undefined
);

const Root = () => {
    // const [converting, setConverting] = useState(true);
    // const [error, setError] = useState(false);
    // const [success, setSuccess] = useState(true);
    const [status, setStatus] = useState('editing');
    const [modal, setModal] = useState(false);
    const [email, setEmail] = useState('');
    const [linkError, setLinkError] = useState(false);
    const [linkSent, setLinkSent] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);

    // audio id
    const [audioId, setAudioId] = useState(undefined);
    const [autoPlay, setAutoPlay] = useState(false);

    // Audio Setting States
    const [moreOptions, setMoreOptions] = useState(true);
    const [speed, setSpeed] = useState(null);
    const [pitch, setPitch] = useState(null);
    const [format, setFormat] = useState('wav');
    // const [speaker, setSpeaker] = useState('hi_female');
    const [language, setLanguage] = useState('hindi');
    const [gender, setGender] = useState(appConfig.defaultSpeaker['hindi']);

    // textarea states
    const [text, setText] = useState('');
    const [cursor, setCursor] = useState(0);
    const [selection, setSelection] = useState(null);
    const [textChanged, setTextChanged] = useState(false);

    const getSpeakerFromLanguage = () => {
        const languageMap = appConfig.languageMap
        let langCode = '';
        Object.keys(languageMap).forEach(l => {
            if (languageMap[l] === language) {
                langCode = l;
            }
        })
        return `${langCode}_${gender}`;
    }

    const convertAudio = (play) => {
        // let text = document.getElementById('textarea').innerHTML;
        // const lt = new RegExp(/&lt;/, "g");
        // text = text.replace(lt, "<");
        // const gt = new RegExp(/&gt;/, "g");
        // text = text.replace(gt, ">");
        // console.log('text', text);
        const body = { ssml: text, format, speed: parseFloat(speed), pitch: parseFloat(pitch) };
        setStatus('converting');
        const speaker = gender;
        axios.post(`${appConfig.backendUrl}convert_audio`, { body, speaker }).then(res => {
            console.log(res);
            if (res.data.success) {
                setAudioId(res.data.data.projectId);
                setStatus('converted');
                setTextChanged(false);
                if (typeof play === "boolean") {
                    setAutoPlay(true)
                }
            } else {
                toastr.warning("Please make sure the syntax is correct.");
                setStatus('convert_error');
            }
        }).catch(err => {
            console.log(err);
            toastr.warning("Please make sure the syntax is correct.");
            setStatus('convert_error');
        })
    }

    const handleEmail = () => {
        if (!email || email.length === 0 || emailVal(email)) {
            // toastr.warning("Please enter a valid email.")
            // setLoading(false);
            setLinkError(true);
            return
        } else {
            setSendingEmail(true)
            axios.post(`${appConfig.backendUrl}send_mail`, { projectId: audioId, email }).then(res => {
                setLinkError(false);
                setModal(false);
                setSendingEmail(false);
                setEmail('');
                setLinkSent(true);
            }).catch(err => {
                console.log(err);
            })
        }
    }

    const handleMoreOptions = () => {
        setMoreOptions(prev => !prev);
    }

    const handleSpeedSelect = (value) => {
        setSpeed(value);
    }

    const handlePitchChange = (value) => {
        setPitch(value);
    }

    const handleLangChange = (value) => {
        setLanguage(value);
        setGender(appConfig.defaultSpeaker[value])
    }

    const resetState = () => {
        setText('');
        // document.getElementById('textarea').innerHTML = '';
        setCursor(0);
        setSelection(null);

        setMoreOptions(true);
        setSpeed(null);
        setPitch(null);
        setFormat('wav');
        // setSpeaker('hi_female');
        setLanguage('hindi');
        setGender(appConfig.defaultSpeaker['hindi']);

        setAudioId(undefined);

        setStatus('editing');
        setModal(false);
        setEmail('');
        setLinkError(false);
        setLinkSent(false);
    }

    const handleSetText = (value) => {
        setText(value);
        setTextChanged(value)
    }

    return (
        <Row justify='center' style={{ marginTop: '2em', marginBottom: '2em' }}>
            <Col span={24}>
                <TextareaConf
                    text={text}
                    cursor={cursor}
                    selection={selection}
                    moreOptions={moreOptions}
                    speed={speed}
                    pitch={pitch}
                    format={format}
                    // speaker={speaker}
                    language={language}
                    gender={gender}
                    setText={handleSetText}
                    setCursor={setCursor}
                    setSelection={setSelection}
                    setFormat={setFormat}
                    // setSpeaker={setSpeaker}
                    // setLanguage={setLanguage}
                    setGender={setGender}
                    handleLangChange={handleLangChange}
                    handleMoreOptions={handleMoreOptions}
                    handleSpeedSelect={handleSpeedSelect}
                    handlePitchChange={handlePitchChange}
                />
                <DownloadCard
                    handleDownloadModal={e => setModal(prev => !prev)}
                    handleTryAgain={e => setStatus('editing')}
                    // converting={converting}
                    // error={error}
                    // success={success}
                    audioId={audioId}
                    status={status}
                    convertAudio={convertAudio}
                    textChanged={textChanged}
                    autoPlay={autoPlay}
                    setAutoPlay={setAutoPlay}
                />
                <Modal
                    visible={modal}
                    closable
                    centered
                    width={600}
                    onCancel={e => setModal(false)}
                    footer={null}
                    bodyStyle={{ borderRadius: '6px', boxShadow: '4px 10px 20px 0 rgba(0, 0, 0, 0.08)' }}
                    style={{ borderRadius: '6px' }}
                >
                    <Text style={{ color: '#040107', fontSize: '14px' }}>Your file is ready for download</Text>
                    <Paragraph style={{ color: '#646a71', marginTop: '1em' }}>
                        One last step!! Please provide us with your email address so that we can send you the file download link.
                    </Paragraph>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Input placeholder="Enter your email id" value={email} onChange={e => setEmail(e.target.value)} style={{ width: '60%', height: '3em' }} />
                        <Button disabled={sendingEmail} type="primary" onClick={handleEmail} className="link-btn">Submit</Button>
                    </div>
                    {linkError && <Paragraph style={{ color: '#c70a00', marginTop: '1em' }}>You have entered an invalid email id, please check and try again.</Paragraph>}
                </Modal>
                <Modal
                    visible={linkSent}
                    closable
                    centered
                    width={600}
                    onCancel={e => setLinkSent(false)}
                    footer={null}
                    bodyStyle={{ borderRadius: '6px', boxShadow: '4px 10px 20px 0 rgba(0, 0, 0, 0.08)' }}
                    style={{ borderRadius: '6px' }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <MarkEmailReadIcon style={{ fill: '#00832d', fontSize: '3em' }} />
                        <Paragraph style={{ color: '#00070c', fontWeight: '500', fontSize: '1.2em' }}>We have sent you the download link.</Paragraph>
                        <Paragraph style={{ color: '#00070c' }}>Thank you for using our platform.</Paragraph>
                        <Button onClick={resetState} type="primary" className="link-btn">Convert New Text</Button>
                    </div>
                </Modal>
            </Col>
        </Row>
    )
}

export default Root;