import React, { useState, useEffect } from 'react';
import { Button, Card, Dropdown, Menu, Select } from 'antd';
import appConfig from '../appConfig';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { startCase } from 'lodash';


const { Option } = Select;

const AudioSettings = ({
    text,
    cursor,
    selection,
    moreOptions,
    speed,
    pitch,
    format,
    // speaker,
    language,
    gender,
    setText,
    setCursor,
    setSelection,
    setFormat,
    // setSpeaker,
    // setLanguage,
    setGender,
    handleLangChange,
    handleMoreOptions,
    handleSpeedSelect,
    handlePitchChange
}) => {
    

    const handleCursor = (e) => {
        const start = document.getElementById('textarea').selectionStart;
        const end = document.getElementById('textarea').selectionEnd;
        // console.log(start, end);
        if(start === end) {
            setCursor(start);
            setSelection(null);
        } else {
            setSelection([start, end]);
            setCursor(null);
        }
    }


    const handlePause = (e) => {
        // console.log('e', e);
        let newText = '';
        if(typeof cursor === "number") {
            newText = text.slice(0, cursor) + `<break time="${e.key}" />` + text.slice(cursor);
        } else {
            newText = text.slice(0, selection[1]) + `<break time="${e.key}" />` + text.slice(selection[1]);
        }
        setText(newText);
    };

    const pauseMenu = (
        <Menu onClick={handlePause}>
            <Menu.Item key="500ms">.5s</Menu.Item>
            <Menu.Item key="1s">1s</Menu.Item>
            <Menu.Item key="1500ms">1.5s</Menu.Item>
        </Menu>
    );

    const handleVoice = (e) => {
        // console.log(e.key);
        let newText = '';
        if(typeof cursor === "number") {
            newText = text.slice(0, cursor) + `<voice name="${e.key}"></voice>` + text.slice(cursor);
        } else {
            newText = text.slice(0, selection[0]) + `<voice name="${e.key}">${text.slice(selection[0], selection[1])}</voice>` + text.slice(selection[1]);
        }
        setText(newText);
    };

    const voiceMenu = (
        <Menu onClick={handleVoice}>
            {appConfig.speakers.filter(s => {
                let langCode = Object.keys(appConfig.languageMap).find(code => appConfig.languageMap[code] === language);
                let currCode = s.split("_")[0];
                if(langCode === currCode) {
                    return true;
                }
                return false;
            }).map(spkr => (
                <Menu.Item key={spkr}>{startCase(spkr)}</Menu.Item>
            ))}
        </Menu>
    );

    const handleSayAs = (e) => {
        // console.log(e);
        if(e.key !== "dmy" && e.key !== "mdy" && e.key !== "md" && e.key !== "dm") {
            let newText = '';
            if(typeof cursor === "number") {
                newText = text.slice(0, cursor) + `<say-as interpret-as="${e.key}"></say-as>` + text.slice(cursor);
            } else {
                newText = text.slice(0, selection[0]) + `<say-as interpret-as="${e.key}">${text.slice(selection[0], selection[1])}</say-as>` + text.slice(selection[1]);
            }
            setText(newText);
        } else {
            let newText = '';
            if(typeof cursor === "number") {
                newText = text.slice(0, cursor) + `<say-as interpret-as="date" format="${e.key}"></say-as>` + text.slice(cursor);
            } else {
                newText = text.slice(0, selection[0]) + `<say-as interpret-as="date" format="${e.key}">${text.slice(selection[0], selection[1])}</say-as>` + text.slice(selection[1]);
            }
            setText(newText);
        }
    };

    const sayAsMenu = (
        <Menu onClick={handleSayAs}>
            <Menu.Item key="cardinal">Cardinal</Menu.Item>
            <Menu.Item key="ordinal">Ordinal</Menu.Item>
            <Menu.Item key="characters">Character</Menu.Item>
            <Menu.Item key="digits">Digits</Menu.Item>
            <Menu.Item key="unit">Unit</Menu.Item>
            <Menu.SubMenu title="Date" key="date">
                <Menu.Item key="dmy">DMY</Menu.Item>
                <Menu.Item key="mdy">MDY</Menu.Item>
                <Menu.Item key="dm">DM</Menu.Item>
                <Menu.Item key="md">Md</Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="currency">Currency</Menu.Item>
            <Menu.Item key="url">URL</Menu.Item>
        </Menu>
    );

    const handleProsody = (e) => {
        // console.log(e);
        let newText = '';
        if(e.key.indexOf('pitch') !== -1) {
            let key = e.key.split('_')[1];
            if(typeof cursor === "number") {
                newText = text.slice(0, cursor) + `<prosody pitch="${key}"></prosody>` + text.slice(cursor);
            } else {
                newText = text.slice(0, selection[0]) + `<prosody pitch="${key}">${text.slice(selection[0], selection[1])}</prosody>` + text.slice(selection[1]);
            }
        } else if(e.key.indexOf('rate') !== -1) {
            let key = e.key.split('_')[1];
            if(typeof cursor === "number") {
                newText = text.slice(0, cursor) + `<prosody rate="${key}"></prosody>` + text.slice(cursor);
            } else {
                newText = text.slice(0, selection[0]) + `<prosody rate="${key}">${text.slice(selection[0], selection[1])}</prosody>` + text.slice(selection[1]);
            }
        } else if(e.key.indexOf('volume') !== -1) {
            let key = e.key.split('_')[1];
            if(typeof cursor === "number") {
                newText = text.slice(0, cursor) + `<prosody volume="${key}"></prosody>` + text.slice(cursor);
            } else {
                newText = text.slice(0, selection[0]) + `<prosody volume="${key}">${text.slice(selection[0], selection[1])}</prosody>` + text.slice(selection[1]);
            }
        } else if(e.key === "duration" || e.key === "contour" || e.key === "range") {
            if(typeof cursor === "number") {
                newText = text.slice(0, cursor) + `<prosody ${e.key}=""></prosody>` + text.slice(cursor);
            } else {
                newText = text.slice(0, selection[0]) + `<prosody ${e.key}="">${text.slice(selection[0], selection[1])}</prosody>` + text.slice(selection[1]);
            }
        }
        setText(newText);
    }

    const prosodyMenu = (
        <Menu onClick={handleProsody}>
            <Menu.SubMenu title="Pitch" key="pitch">
                <Menu.Item key="pitch_default">Default</Menu.Item>
                <Menu.Item key="pitch_x-low">X-Low</Menu.Item>
                <Menu.Item key="pitch_low">Low</Menu.Item>
                <Menu.Item key="pitch_medium">Medium</Menu.Item>
                <Menu.Item key="pitch_high">High</Menu.Item>
                <Menu.Item key="pitch_x-high">X-High</Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title="Rate" key="rate">
                <Menu.Item key="rate_default">Default</Menu.Item>
                <Menu.Item key="rate_x-slow">X-Slow</Menu.Item>
                <Menu.Item key="rate_slow">Slow</Menu.Item>
                <Menu.Item key="rate_medium">Medium</Menu.Item>
                <Menu.Item key="rate_fast">Fast</Menu.Item>
                <Menu.Item key="rate_x-fast">X-Fast</Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="duration">Duration</Menu.Item>
            <Menu.SubMenu title="Volume" key="volume">
                <Menu.Item key="volume_default">Default</Menu.Item>
                <Menu.Item key="volume_silent">Silent</Menu.Item>
                <Menu.Item key="volume_x-soft">X-Soft</Menu.Item>
                <Menu.Item key="volume_soft">Soft</Menu.Item>
                <Menu.Item key="volume_medium">Medium</Menu.Item>
                <Menu.Item key="volume_loud">Loud</Menu.Item>
                <Menu.Item key="volume_x-loud">X-Loud</Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="contour">Contour</Menu.Item>
            <Menu.Item key="range">Range</Menu.Item>
        </Menu>
    );

    const pitchMenu = (
        <Menu onClick={handleProsody}>
            <Menu.Item key="pitch_default">Default</Menu.Item>
            <Menu.Item key="pitch_x-low">X-Low</Menu.Item>
            <Menu.Item key="pitch_low">Low</Menu.Item>
            <Menu.Item key="pitch_medium">Medium</Menu.Item>
            <Menu.Item key="pitch_high">High</Menu.Item>
            <Menu.Item key="pitch_x-high">X-High</Menu.Item>
        </Menu>
    );

    const rateMenu = (
        <Menu onClick={handleProsody}>
            <Menu.Item key="rate_default">Default</Menu.Item>
            <Menu.Item key="rate_x-slow">X-Slow</Menu.Item>
            <Menu.Item key="rate_slow">Slow</Menu.Item>
            <Menu.Item key="rate_medium">Medium</Menu.Item>
            <Menu.Item key="rate_fast">Fast</Menu.Item>
            <Menu.Item key="rate_x-fast">X-Fast</Menu.Item>
        </Menu>
    );

    const volumeMenu = (
        <Menu onClick={handleProsody}>
            <Menu.Item key="volume_default">Default</Menu.Item>
            <Menu.Item key="volume_silent">Silent</Menu.Item>
            <Menu.Item key="volume_x-soft">X-Soft</Menu.Item>
            <Menu.Item key="volume_soft">Soft</Menu.Item>
            <Menu.Item key="volume_medium">Medium</Menu.Item>
            <Menu.Item key="volume_loud">Loud</Menu.Item>
            <Menu.Item key="volume_x-loud">X-Loud</Menu.Item>
        </Menu>
    )

    const initSwalekh = (value) => {
        console.log('reached')
        const id = 'textarea';
        const keyboard = true;
        const keyboardMode = 'phonetic';
        // const language = language;
        const languageMap = appConfig.languageMap;
        let lang;
        Object.keys(languageMap).map(l => {
            if(languageMap[l] === language) {
                lang = l
            }
        })
        if(value) {
            lang = value;
        }
        let mode = keyboardMode;
        // console.log(language)
        if(language === "en") {
            console.log('reached 1 lang english')
            return;
        }
        // if (NMT_LANGUAGES.indexOf(language) === -1) {
        //     console.log('reached 2 not nmt lang');
        //     mode = 'keyboard';
        // }
        if (!keyboard) {
            console.log('reached 3 !keyboard')
            return;
        }
        const $ = window.$;
        // const index = id.split('-')[1];
        // if(currSubIndex !== index) {
            // deactivateSwalekh(`textarea`)
        // }
        // setCurrSubIndex(index);
      //   const $sub = $(`#${id}`);
        // console.log($sub)
        if ($(`#${id}`).attr('data-indic-input-mode') && $(`#${id}`).attr('data-indic-input-mode') !== keyboardMode) {
            console.log('Swalekh already initialized');
            return;
        }
        // console.log('reached hwrw')
        // $(`#${id}`).indicInputEditor({
        //     apikey: '172c5bb5af18516905473091fd58d30afe740b3f',
        //     appid: 'rev.transliteration',
        //     orgName: 'Prabandhak',
        //     numSuggestions: 7,
        //     api_endpoint: 'https://revapi.reverieinc.com/',
        //     mode,
        //     domain: 1,
        //     theme: 'light'
        // });
        $(`#${id}`).indicInput({
            // apikey: '172c5bb5af18516905473091fd58d30afe740b3f',
            // appid: 'rev.transliteration',  
            orgName: 'Prabandhak',
            api_endpoint: 'https://revapi.reverieinc.com',
            apikey: '350c950e2caa2814fb11187137510f47c84801bc',
            appid: 'rev.dictation',
            numSuggestions: 7,
            mode,
            domain: 1,
            theme: 'light'
        });
        // if (NMT_LANGUAGES.indexOf(targetLanguage) === -1) {
        //   $(`#${segment.segmentId}`).trigger('change_mode', mode);
        // }
        // console.log('reached 4')
        $(`#${id}`).trigger('change_lang', lang);
    }

    const deactivateSwalekh = () => {
        const $ = window.$;
        const id = 'textarea';
        if ($(`#${id}`).data('mode')) {
            if ($(`#${id}`).getkeyboard()) {
                $(`#${id}`).removeClass('ui-keyboard-autoaccepted');
                $(`#${id}`).removeAttr('data-indic-input-mode');
                $(`#${id}`).getkeyboard().destroy();
            } else {
                $(`#${id}`).removeAttr('data-indic-input-mode');
                setTimeout(() => {
                    $(`#${id}`).textcomplete('destroy');
                }, 100);
            }
        }
    }

    const handleSwalekhLangChange = (value) => {
        handleLangChange(value);
        const languageMap = appConfig.languageMap;
        let currLang;
        Object.keys(languageMap).map(l => {
            if(languageMap[l] === value) {
                currLang = l
            }
        })
        const id = "textarea";
        const $ = window.$;
        // console.log('reached change lang', currLang);
        if(currLang === "en") {
            deactivateSwalekh();
        } else {
            if (!$(`#${id}`).attr('data-indic-input-mode') && !$(`#${id}`).attr('data-indic-input-mode') !== 'phonetic') {
                console.log('Swalekh already initialized');
                // return;
                initSwalekh(value);
            } else {
                $(`#textarea`).trigger('change_lang', value);
            }
        }
    }

    const handleTextChange = (e) => {
        setText(e.target.value)
    }

    useEffect(() => {
        initSwalekh();
        const $textarea = document.getElementById('textarea');
        $textarea.addEventListener('change', handleTextChange);
        return () => {
            $textarea.removeEventListener('change', handleTextChange);
        }
    }, [])

    return (
        <Card style={{ border: 'solid .5px #dadce0', boxShadow: '6px 8px 30px 0 rgba(0, 0, 0, 0.06)', borderRadius: '10px' }} bodyStyle={{ padding: '0' }}>
            <div style={{ height: 'auto', borderBottom: '.5px solid #dadce0', padding: '1em 1.5em', display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '-1em' }}>
                <span className="flex-item" style={{ color: '#777c86' }}>Add</span>
                <Select className="select short flex-item" placeholder="Select Voice" value={language} onChange={handleSwalekhLangChange} style={{ marginLeft: '1em', textTransform: 'capitalize' }}>
                    {appConfig.languages.map(spkr => (
                        <Option key={spkr} value={spkr} style={{ textTransform: 'capitalize' }}>{spkr}</Option>
                    ))}
                </Select>
                <Select className="select short flex-item" placeholder="Select Gender" value={gender} onChange={value => setGender(value)} style={{ marginLeft: '1em', textTransform: 'capitalize' }}>
                    {/* <Option key="female" value="female">Female</Option>
                    {language !== "english" && <Option key="male" value="male">Male</Option>} */}
                    {appConfig.speakers.filter(s => {
                        let langCode = Object.keys(appConfig.languageMap).find(code => appConfig.languageMap[code] === language);
                        let currCode = s.split("_")[0];
                        if(langCode === currCode) {
                            return true;
                        }
                        return false;
                    }).map(spkr => (
                        <Option value={spkr}>{startCase(spkr)}</Option>
                    ))}
                </Select>
                <Dropdown overlay={voiceMenu} trigger={["click"]}>
                    {/* <AudioConfButton name="Pauses" /> */}
                    <span onClick={e => e.preventDefault()} className="dropdown-span flex-item">
                        Voices <KeyboardArrowDownIcon style={{ marginLeft: '2em' }} />
                    </span>
                </Dropdown>
                <Dropdown overlay={pauseMenu} trigger={["click"]}>
                    {/* <AudioConfButton name="Pauses" /> */}
                    <span onClick={e => e.preventDefault()} className="dropdown-span flex-item">
                        Pauses <KeyboardArrowDownIcon style={{ marginLeft: '2em' }} />
                    </span>
                </Dropdown>
                <Dropdown overlay={sayAsMenu} trigger={["click"]}>
                    {/* <AudioConfButton name="Pauses" /> */}
                    <span onClick={e => e.preventDefault()} className="dropdown-span flex-item">
                        Say As <KeyboardArrowDownIcon style={{ marginLeft: '2em' }} />
                    </span>
                </Dropdown>
                {/* <Dropdown overlay={prosodyMenu} trigger={["click"]}>
                    <span onClick={e => e.preventDefault()} className="dropdown-span flex-item">
                        Prosody <KeyboardArrowDownIcon style={{ marginLeft: '2em' }} />
                    </span>
                </Dropdown> */}
                <Dropdown overlay={pitchMenu} trigger={["click"]}>
                    <span onClick={e => e.preventDefault()} className="dropdown-span flex-item">
                        Pitch <KeyboardArrowDownIcon style={{ marginLeft: '2em' }} />
                    </span>
                </Dropdown>
                <Dropdown overlay={rateMenu} trigger={["click"]}>
                    <span onClick={e => e.preventDefault()} className="dropdown-span flex-item">
                        Rate <KeyboardArrowDownIcon style={{ marginLeft: '2em' }} />
                    </span>
                </Dropdown>
                <Dropdown overlay={volumeMenu} trigger={["click"]}>
                    <span onClick={e => e.preventDefault()} className="dropdown-span flex-item">
                        Volume <KeyboardArrowDownIcon style={{ marginLeft: '2em' }} />
                    </span>
                </Dropdown>
                <Button onClick={e => handleProsody({ key: 'duration' })} className="dropdown-span flex-item btn" style={{ padding: '0 2em' }}>Duration</Button>
                <Button onClick={e => handleProsody({ key: 'contour' })} className="dropdown-span flex-item btn" style={{ padding: '0 2em' }}>Contour</Button>
                <Button onClick={e => handleProsody({ key: 'range' })} className="dropdown-span flex-item btn" style={{ padding: '0 2em' }}>Range</Button>
            </div>
            <div onClick={handleMoreOptions} style={{ height: '5em', borderBottom: '.5px solid #dadce0', padding: '1em 1.5em', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                {!moreOptions && <AddIcon style={{ fill: '#777c86' }} />}
                {moreOptions && <RemoveIcon style={{ fill: '#777c86' }} />}
                <span style={{ marginLeft: '1em', color: '#777c86' }}>More Audio Manipulation Options</span>
            </div>
            {moreOptions && <div style={{ height: '5em', borderBottom: '.5px solid #dadce0', padding: '1em 1.5em', display: 'flex', alignItems: 'center' }}>
                <Select className="select" placeholder="Select Speed (Optional)" value={speed} onChange={handleSpeedSelect} style={{ marginRight: '1em' }}>
                    <Option key="0.5" value="0.5">0.5</Option>
                    <Option key="0.7" value="0.7">0.7</Option>
                    <Option key="0.9" value="0.9">0.9</Option>
                    <Option key="1.0" value="1.0">1.0</Option>
                    <Option key="1.1" value="1.1">1.1</Option>
                    <Option key="1.3" value="1.3">1.3</Option>
                    <Option key="1.5" value="1.5">1.5</Option>
                </Select>
                <Select className="select" placeholder="Select Pitch (Optional)" value={pitch} onChange={handlePitchChange} style={{ marginRight: '1em' }}>
                    <Option key="-3" value="-3">-3</Option>
                    <Option key="-2" value="-2">-2</Option>
                    <Option key="-1" value="-1">-1</Option>
                    <Option key="0" value="0">0</Option>
                    <Option key="1" value="1">1</Option>
                    <Option key="2" value="2">2</Option>
                    <Option key="3" value="3">3</Option>
                </Select>
                <Select className="select" placeholder="Select Format" value={format} onChange={value => setFormat(value)} style={{ marginRight: '1em' }}>
                    <Option key="wav" value="wav">WAV</Option>
                    <Option key="mp3" value="mp3">MP3</Option>
                    <Option key="flac" value="flac">FLAC</Option>
                    <Option key="ogg" value="ogg">OGG</Option>
                    <Option key="opus" value="opus">OPUS</Option>
                    <Option key="pcm" value="pcm">PCM</Option>
                </Select>
            </div>}
            <div style={{ height: '400px', position: 'relative' }}>
                <textarea id="textarea" onBlur={handleCursor} value={text} onChange={e => setText(e.target.value)} maxLength={300} style={{ height: '100%', width: '100%', padding: '1em', outline: 'none', border: 'none', resize: 'none', borderRadius: '10px' }} placeholder="Type your text here..." />
                <div style={{ position: 'absolute', bottom: '1em', right: '1em' }}>
                    <span style={{ color: '#93979f', marginRight: '1.5em' }}>{text.length} / 300 characters used</span>
                    <span onClick={e => setText('')} style={{ textDecoration: 'underline', color: '#93979f', cursor: 'pointer' }}>Clear All Text</span>
                </div>
            </div>
        </Card>
    )
}

export default AudioSettings;